import React from 'react';

const WaveHeader = ({ name }) => {
  return (
    <div className="relative h-20 bg-gradient-to-l from-[#C6C1E7] to-[#0A379F] overflow-hidden wave shadowheader">
      <div className="flex justify-start items-center m-4 text-white text-md font-bold">
        Welcome &nbsp;
        <span className='capitalize'>{name}!</span>
      </div>
    </div>
  );
};

export default WaveHeader;