/* eslint-disable */

import { Link, useLocation } from "react-router-dom";
// chakra imports

export const SidebarLinks = (props: { routes: RoutesType[] }): JSX.Element => {
  // Chakra color mode
  let location = useLocation();

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes: RoutesType[]) => {
    return routes.map((route, index) => {
      if (
        route.layout === "/game" ||
        route.layout === "/instructor" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        if (!["game1B","Login","Unauthorised"].includes(route.name)) {
          return (
            <Link key={index} to={route.layout + "/" + route.path}>
              <div className="relative flex hover:cursor-pointer">
                <li
                  className="flex cursor-pointer items-center pl-[2vw]"
                  key={index}
                >
                  <span
                    className={`${
                      activeRoute(route.path) === true
                        ? "font-semibold text-blue-800 dark:text-white underline hover:text-[#23527c] hover:underline"
                        : "font-medium text-blue-800 hover:text-[#23527c] hover:underline "
                    }`}
                  >
                  </span>
                  <p
                    className={`sm:leading-5 flex ${
                      activeRoute(route.path) === true
                        ? "font-semibold text-blue-800 dark:text-white underline hover:text-[#23527c] hover:underline"
                        : "font-medium text-blue-800 hover:text-[#23527c] hover:underline"
                    }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path) ? (
                  <div className="text-decoration-line: underline" />
                ) : null}
              </div>
            </Link>
          );
        }
      }
    });
  };
  // BRAND
  return <>{createLinks(routes)}</>;
};

export default SidebarLinks;