import React, { useState } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import routes from "routes";
import Header from "components/header";
import FooterCustom from "components/footer/FooterCustom";
import "assets/css/App.css";
import Chatbot from "views/chatbot/components/chatbot";
import { useAuth } from "config/AuthDataProvider";
import { usePusher } from "context/pusher/PusherContext";
import { toast } from "react-toastify";
import { useGameStore } from "store/store";

import axios from "axios";
import { getEnvironment } from "../../config/environment";
import { Box, ChakraProvider, Flex } from "@chakra-ui/react";

/* eslint-disable @typescript-eslint/no-unused-vars */
/* @ts-ignore */

export default function Admin(props: { [x: string]: any }) {
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  const authData = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const pusher = usePusher();
  // const [ alertMessage, setAlertMessage ] = React.useState();

  const { Game2reset, setauthData, Game3reset, Game1reset } =
  useGameStore();

const env = getEnvironment();
  
  React.useEffect(() => {
    function childEventCallback(data) {
    toast.error(

        <>
          <div className="font-Helvetica text-lg font-bold tracking-wide">
            {`${data?.title}`}
          </div>
          <div className="font-Helvetica text-base tracking-wide">
          {`${data?.message}`}
          </div>
        </>,
        {
        position: "top-center",
        autoClose: 300000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    function logoutEventCallback(data) {
      toast.error(
          <>
            <div className="font-Helvetica text-lg font-bold tracking-wide">
              {`${data?.title}`}
            </div>
            <div className="font-Helvetica text-base tracking-wide">
            {`${data?.message}`}
            </div>
          </>,
          {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          onClose: redirect,
          theme: "colored",
        });
      }

    let subscriber = authData?.teamname.split(" ").join("")

    const channel = pusher.subscribe(subscriber);
    channel.bind("my-event", childEventCallback);
    channel.bind("logout-event", logoutEventCallback);

    return () => {
      channel.unbind("my-event", childEventCallback);
      channel.unbind("logout-event", logoutEventCallback);
    };
  }, [pusher]);

  function clearData() {
    localStorage.removeItem(`user_${authData.username}_price`);
    pusher.disconnect()
    setauthData(null);
    Game1reset();
    Game2reset();
    Game3reset();
    navigate("/auth/login");
  }

  const redirect = async () => {
    await axios
      .post(`${env.endpoint}/tenant/logout/`, { refresh: authData.refresh })
      .then(() => {
        clearData();
      })
      .catch(() => {
        clearData();
      });
  };

  const getActiveRoute = (routes: RoutesType[]): string | boolean => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
        setIsOpen(false);
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/game") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  document.documentElement.dir = "ltr";
  if (!authData || !authData.isPlayer) {
    navigate(`/auth/login`);
  }
  else {
    return (
      <ChakraProvider>
        <Flex direction="column" height="100vh">
          <Header setIsOpen={setIsOpen} />
          <Box flexGrow={1} bg="gray.100" bgGradient="linear(to-r, #F04E4A,#0537A0)" p={4} id="first">
              <Routes>
                {getRoutes(routes)}
                <Route path="/" element={<Navigate to="/auth/login" replace />} />
              </Routes>
          </Box>
          <FooterCustom />
          <Transition appear show={isOpen}>
            <Box position="fixed" top={10} right={0} zIndex={999}>
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Chatbot onClose={closeModal} />
              </Transition.Child>
            </Box>
          </Transition>
        </Flex>
      </ChakraProvider>
    );
  }
}
