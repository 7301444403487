import React from "react";
import { Box, Heading, Text, Button, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const UnAuthorisedPage = () => {
  return (
    <Box
      as={Flex}
      textAlign="center"
      gap={5}
      py={10}
      px={6}
      h={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
    >
      <Heading
        display="block"
        as="h4"
        size="xl"
        bg={"black"}
        backgroundClip="text"
      >
        Unathorised Acesss
      </Heading>
      <Text color={"white"} mb={6}>
        Oopsss !! You are not Supposed to see this page
      </Text>

      <Button
        as={Link}
        to="/game/about"
        colorScheme="teal"
        bgGradient="linear(to-b, #5C86DC,#2647B0)"
        color="white"
        variant="solid"
        _hover={{}}
        _active={{}}
      >
        Go to Home
      </Button>
    </Box>
  );
};

export default UnAuthorisedPage;
