import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  VStack,
  useColorModeValue,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  useBreakpointValue,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import {
  FaChevronRight,
  FaChevronLeft,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { SearchIcon } from "@chakra-ui/icons";

const CustomMultiSelect = ({
  options,
  selectedOptions,
  onSelectionChange,
  filter,
  onFilterChange,
  Menutitle,
}) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box
      borderWidth={1}
      borderColor={borderColor}
      borderTopRadius="md"
      bg={"#FFFFFF80"}
      height="300px"
      overflow={"auto"}
    >
      <Flex
        bg={"TableHeaderbg"}
        justify="space-between"
        align={"center"}
        textColor="#0B389E"
        fontWeight={500}
        fontSize={"16px"}
        h={10}
        p={3}
      >
        {Menutitle}
      </Flex>
      <Flex>
        <InputGroup mx={1} my={2}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="#000000" />
          </InputLeftElement>
          <Input
            id="tenant-globalsearch"
            placeholder="Search..."
            value={filter}
            onChange={(e) => onFilterChange(e.target.value)}
            bgColor="#FFFFFF"
            borderRadius={"8px"}
          />
        </InputGroup>
      </Flex>
      <Flex direction="column" justifyContent={"center"} pl={3}>
        {options
          .filter((option) =>
            option.toLowerCase().includes(filter.toLowerCase())
          )
          .map((option) => (
            <Checkbox
              key={option}
              isChecked={selectedOptions.includes(option)}
              onChange={() => onSelectionChange(option)}
              mb={2}
            >
              {option}
            </Checkbox>
          ))}
      </Flex>
    </Box>
  );
};

const PermissionManagement = () => {
  const [groupName, setGroupName] = useState("Instructor");
  const [availablePermissions, setAvailablePermissions] = useState([
    "Administration | log entry | Can add log entry",
    "Administration | log entry | Can change log entry",
    "Administration | log entry | Can delete log entry",
    "Administration | log entry | Can view log entry",
    "Authentication and Authorization | group | Can add group",
    "Authentication and Authorization | group | Can change group",
    "Authentication and Authorization | group | Can delete group",
    "Authentication and Authorization | group | Can view group",
    "Authentication and Authorization | permission | Can add permission",
    "Authentication and Authorization | permission | Can change permission",
    "Authentication and Authorization | permission | Can delete permission",
    "Authentication and Authorization | permission | Can view permission",
    "Authentication and Authorization | user | Can add user",
  ]);
  const [chosenPermissions, setChosenPermissions] = useState([
    "Roai | game metrics | Can add game metrics",
    "Roai | game metrics | Can change game metrics",
    "Roai | game metrics | Can delete game metrics",
    "Roai | game metrics | Can view game metrics",
    "Roai | team | Can add team",
    "Roai | team | Can change team",
    "Roai | team | Can delete team",
    "Roai | team | Can view team",
  ]);
  const [availableFilter, setAvailableFilter] = useState("");
  const [chosenFilter, setChosenFilter] = useState("");
  const [selectedAvailable, setSelectedAvailable] = useState([]);
  const [selectedChosen, setSelectedChosen] = useState([]);

  const bgColor = useColorModeValue("gray.100", "gray.700");

  const availablePermissionIcon = useBreakpointValue({
    base: <FaChevronDown />,
    md: <FaChevronRight />,
  });

  const chosenPermissionIcon = useBreakpointValue({
    base: <FaChevronUp />,
    md: <FaChevronLeft />,
  });

  const handleAvailableSelectionChange = (permission) => {
    setSelectedAvailable((prevSelected) =>
      prevSelected.includes(permission)
        ? prevSelected.filter((p) => p !== permission)
        : [...prevSelected, permission]
    );
  };

  const handleChosenSelectionChange = (permission) => {
    setSelectedChosen((prevSelected) =>
      prevSelected.includes(permission)
        ? prevSelected.filter((p) => p !== permission)
        : [...prevSelected, permission]
    );
  };

  const moveToChosen = () => {
    setChosenPermissions((prev) => [...prev, ...selectedAvailable]);
    setAvailablePermissions((prev) =>
      prev.filter((p) => !selectedAvailable.includes(p))
    );
    setSelectedAvailable([]);
  };

  const moveToAvailable = () => {
    setAvailablePermissions((prev) => [...prev, ...selectedChosen]);
    setChosenPermissions((prev) =>
      prev.filter((p) => !selectedChosen.includes(p))
    );
    setSelectedChosen([]);
  };

  const handleChooseAll = () => {
    setChosenPermissions([...chosenPermissions, ...availablePermissions]);
    setAvailablePermissions([]);
  };

  const handleRemoveAll = () => {
    setAvailablePermissions([...availablePermissions, ...chosenPermissions]);
    setChosenPermissions([]);
  };

  return (
    <Box
      id="addtenant-form"
      overflowX="auto"
      //   bg={"#D9D9D999"}
      borderRadius={"20px"}
      display="flex"
      flexDirection="column"
      flex={1}
      fontFamily="Poppins"
      mx={{ base: "0vw", md: "15vw" }}
    >
      <Flex direction={"column"} flex={1}>
        <Flex
          bg={"TableHeaderbg"}
          justify="space-between"
          align={"center"}
          py={"10px"}
          px={"20px"}
          textColor="#0B389E"
          fontWeight={600}
          fontSize={"16px"}
        >
          Roles & Permissions
        </Flex>
        <Flex
          bg={"Containerbg"}
          flex={1}
          py={"10px"}
          px={"20px"}
          direction={"column"}
          justifyContent="space-between"
        >
          <Flex id="form-box" gap={4} direction={"column"}>
            <VStack spacing={4} align="stretch">
              <Box>
                <Text mb={2}>Name:</Text>
                <Input
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  bg="InputBoxbg"
                  placeholder="Role Name"
                  _placeholder={{ color: "PlaceHolderColor" }}
                />
              </Box>

              <Box>
                <FormControl isRequired>
                  <FormLabel>Permissions:</FormLabel>
                </FormControl>
                <Flex direction={{ base: "column", md: "row" }}>
                  <VStack
                    flex={1}
                    align="stretch"
                    mr={{ base: 0, md: 4 }}
                    id="available_permissions"
                    gap={0}
                  >
                    <CustomMultiSelect
                      options={availablePermissions}
                      selectedOptions={selectedAvailable}
                      onSelectionChange={handleAvailableSelectionChange}
                      filter={availableFilter}
                      onFilterChange={setAvailableFilter}
                      Menutitle={"Available permissions"}
                    />
                    <Flex
                      as="button"
                      onClick={handleChooseAll}
                      justifyContent={"center"}
                      alignItems={"center"}
                      py={2}
                      bg={"#FFFFFF80"}
                      borderTopColor={"white"}
                      borderTopWidth={2}
                      fontFamily={"inter"}
                      color={"#3558BC"}
                      fontWeight={500}
                    >
                      Choose all
                    </Flex>
                  </VStack>

                  <VStack
                    justifyContent="center"
                    spacing={4}
                    id="new"
                    flexDirection={{ base: "row", md: "column" }}
                    my={{ base: 4, md: 1 }}
                  >
                    <IconButton
                      aria-label="Move To Chosen"
                      onClick={moveToChosen}
                      isDisabled={selectedAvailable.length === 0}
                      icon={availablePermissionIcon}
                    />
                    <IconButton
                      aria-label="Move To Available"
                      onClick={moveToAvailable}
                      isDisabled={selectedChosen.length === 0}
                      icon={chosenPermissionIcon}
                    />
                  </VStack>

                  <VStack
                    flex={1}
                    align="stretch"
                    ml={{ base: 0, md: 4 }}
                    id="chosen_permissions"
                    gap={0}
                  >
                    <CustomMultiSelect
                      options={chosenPermissions}
                      selectedOptions={selectedChosen}
                      onSelectionChange={handleChosenSelectionChange}
                      filter={chosenFilter}
                      onFilterChange={setChosenFilter}
                      Menutitle={"Chosen permissions"}
                    />
                    <Flex
                      as="button"
                      onClick={handleRemoveAll}
                      justifyContent={"center"}
                      alignItems={"center"}
                      py={2}
                      bg={"#FFFFFF80"}
                      borderTopColor={"white"}
                      borderTopWidth={2}
                      fontFamily={"inter"}
                      color={"#3558BC"}
                      fontWeight={500}
                    >
                      Remove All
                    </Flex>
                  </VStack>
                </Flex>
                <Flex
                  id="button-box"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  gap={3}
                  mt={4}
                >
                  <Button
                    borderRadius="8px"
                    bg="white"
                    textColor="#0B389E"
                    border="2px"
                    borderColor="#5C85DC"
                    // onClick={() => resetForm()}
                  >
                    Cancel
                  </Button>
                  <Button
                    borderRadius="8px"
                    bgGradient="linear(to-b, #5C86DC,#2647B0)"
                    textColor="white"
                    // onClick={handleSubmit}
                    // isLoading={isLoading}
                  >
                    Confirm
                  </Button>
                </Flex>
              </Box>
            </VStack>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default PermissionManagement;
