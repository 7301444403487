import React, { useState, useEffect } from 'react';

const OptimizedImageCarousel = ({ images, initialImage, currentIndex, setCurrentIndex, close , setLoading }) => {
  const [currentImage, setCurrentImage] = useState(initialImage);
  const [nextImage, setNextImage] = useState(null);
  const [ImageLoading ,setImageLoading] = useState(null);

  useEffect(() => {
    const preloadImages = () => {
      const img = new Image();
      img.src = images[currentIndex];
      img.onload = () => {
        setImageLoading(false);
        setCurrentImage(images[currentIndex]);
      };
      
      if (currentIndex < images.length - 1) {
        const nextImg = new Image();
        nextImg.src = images[currentIndex + 1];
        nextImg.onload = () => {
          setNextImage(images[currentIndex + 1]);
        };
      }
    };

    preloadImages();
  }, [currentIndex, images]);

  return (
    <div className="relative w-full">
      <div className="relative aspect-w-16 aspect-h-9 overflow-hidden">
        <img
          src={close ? initialImage : currentImage}
          alt={`Image ${currentIndex}`}
          className={`w-full h-full object-cover transition-opacity duration-300 ${
            ImageLoading ? 'opacity-0' : 'opacity-100'
          }`}
          onLoad={() => setLoading(true)}
        />
        {ImageLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-200">
            <div className="w-10 h-10 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OptimizedImageCarousel;