import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  HStack,
  Text,
  Box,
  VStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Flex,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { SearchIcon } from "@chakra-ui/icons";

interface ClientData {
  id: string;
  name: string;
  created_date: string;
  price_elasticity: number;
  time_to_patron: number;
}

const ClientListingTable = ({ data }: { data: ClientData[] }) => {
  const [selectedClient, setSelectedClient] = useState<ClientData | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const columns = useMemo<ColumnDef<ClientData>[]>(
    () => [
      {
        id: "actions",
        cell: (info) => (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<BsThreeDotsVertical />}
              variant="ghost"
              size="sm"
            />
            <MenuList>
              <MenuItem onClick={() => handleAction("view", info.row.original)}>
                View
              </MenuItem>
              <MenuItem onClick={() => handleAction("edit", info.row.original)}>
                Edit
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
      // {
      //   header: "ID",
      //   cell: (row) => row.renderValue(),
      //   accessorKey: "id",
      // },
      {
        header: "Name",
        cell: (row) => row.renderValue(),
        accessorKey: "name",
      },
      {
        header: "Created Date",
        cell: (row) => row.renderValue(),
        accessorKey: "created_date",
      },
      // {
      //   header: "Price Elasticity",
      //   cell: (row) => row.renderValue(),
      //   accessorKey: "price_elasticity",
      // },
      // {
      //   header: "Time To Patron",
      //   cell: (row) => row.renderValue(),
      //   accessorKey: "time_to_patron",
      // },
    ],
    []
  );

  const handleAction = (action: "view" | "edit", client: ClientData) => {
    setSelectedClient(client);
    onOpen();
  };

  useEffect(() => {
    const updateRowsPerPage = () => {
      if (tableContainerRef.current) {
        const containerHeight = tableContainerRef.current.clientHeight;
        const headerHeight = 60; // Approximate height of the header row
        const rowHeight = 50; // Approximate height of each data row
        const footerHeight = 46; // Approximate height of the pagination footer
        const availableHeight = containerHeight - headerHeight - footerHeight;
        const calculatedRows = Math.floor(availableHeight / rowHeight);
        setRowsPerPage(Math.max(calculatedRows, 1)); // Ensure at least 1 row is shown
      }
    };

    updateRowsPerPage();
    window.addEventListener("resize", updateRowsPerPage);

    return () => window.removeEventListener("resize", updateRowsPerPage);
  }, []);

  return (
    <VStack align="stretch" spacing={4} h={"full"}>
      <TableListing
        data={data}
        columns={columns}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        rowsPerPage={rowsPerPage}
        tableContainerRef={tableContainerRef}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedClient?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>ID: {selectedClient?.id}</Text>
            <Text>Created Date: {selectedClient?.created_date}</Text>
            <Text>Price Elasticity: {selectedClient?.price_elasticity}</Text>
            <Text>Time To Patron: {selectedClient?.time_to_patron}</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost">Edit</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

function TableListing({
  data,
  columns,
  globalFilter,
  setGlobalFilter,
  rowsPerPage,
  tableContainerRef,
}: {
  data: ClientData[];
  columns: ColumnDef<ClientData>[];
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  rowsPerPage: number;
  tableContainerRef: React.RefObject<HTMLDivElement>;
}) {
  const [pageIndex, setPageIndex] = useState(0);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      globalFilter,
      pagination: {
        pageIndex,
        pageSize: rowsPerPage,
      },
    },
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: (updater) => {
      if (typeof updater === "function") {
        const newState = updater({ pageIndex, pageSize: rowsPerPage });
        setPageIndex(newState.pageIndex);
      } else {
        setPageIndex(updater.pageIndex);
      }
    },
    pageCount: Math.ceil(data.length / rowsPerPage),
    globalFilterFn: "auto",
  });

  useEffect(() => {
    table.setPageSize(rowsPerPage);
  }, [rowsPerPage, table]);

  const handleCreateClient = () => {
    console.log("Create client clicked");
  };

  return (
    <Box
      mx={{ base: "0vw", md: "15vw" }}
      id="tablebox"
      bg={"Containerbg"}
      borderRadius={"20px"}
      display="flex"
      flexDirection="column"
      flex={1}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        justifyContent="space-between"
        alignItems={{ base: "stretch", md: "center" }}
        pb={2}
        pt={4}
        pl={{ base: 4, md: 14 }}
        pr={4}
        gap={4}
      >
        <InputGroup             width={{ base: "100%", md: "50%" }}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="#000000" />
          </InputLeftElement>
          <Input
            id="client-globalsearch"
            placeholder="Search..."
            value={globalFilter ?? ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            bgColor="#FFFFFF"
            borderRadius={"8px"}
          />
        </InputGroup>
        <Button
          id="add-client"
          bgGradient="linear(to-b, #5C86DC,#2647B0)"
          onClick={handleCreateClient}
          width={{ base: "100%", md: "auto" }}
          textColor={"white"}
          fontWeight={700}
          fontFamily={"Poppins"}
          px={4}
          py={2}
          borderRadius={"8px"}
        >
          Create Client
        </Button>
      </Flex>
      <TableContainer
        width="100%"
        id="tablecontainer"
        ref={tableContainerRef}
        flex={1}
        overflowY="auto"
      >
        <Table
          variant="simple"
          size="sm"
          width="100%"
          py={2}
          p={2}
          style={{ borderCollapse: "separate", borderSpacing: "8px" }}
        >
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <Th
                    key={header.id}
                    color="white"
                    textTransform="capitalize"
                    py={3}
                    fontFamily={"Poppins"}
                    textColor={"#0B389E"}
                    fontSize={16}
                    fontWeight={"600"}
                    borderBottom={0}
                    bg={index === 0 ? "transparent" : "TableHeaderbg"}
                    width={index === 0 ? "1px" : "50%"}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell, index) => (
                  <Td
                    key={cell.id}
                    py={2}
                    fontSize="sm"
                    borderBottom={0}
                    bg={index === 0 ? "transparent" : "TableCellbg"}
                    px={index === 0 ? "0px" : 4}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <HStack justify="space-between" w="full" py={2} px={10}>
        <HStack>
          <Button
            id="first-pagebtn"
            size="sm"
            onClick={() => table.setPageIndex(0)}
            isDisabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </Button>
          <Button
            id="previous-pagebtn"
            size="sm"
            onClick={() => table.previousPage()}
            isDisabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </Button>
          <Button
            id="next-pagebtn"
            size="sm"
            onClick={() => table.nextPage()}
            isDisabled={!table.getCanNextPage()}
          >
            {">"}
          </Button>
          <Button
            id="last-pagebtn"
            size="sm"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            isDisabled={!table.getCanNextPage()}
          >
            {">>"}
          </Button>
        </HStack>
        <Text fontSize="sm">
          Page{" "}
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
        </Text>
      </HStack>
    </Box>
  );
}

export default ClientListingTable;
