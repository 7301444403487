import React, { useState, useCallback } from "react";
import { useDropzone, FileRejection, DropzoneOptions } from "react-dropzone";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  VStack,
  Image,
  useToast,
  BoxProps,
  FlexProps,
} from "@chakra-ui/react";
import uploadImage from "../../../../assets/systemadmin/upload.png";
import { useLocation, useNavigate } from "react-router-dom";

interface FileUploadProps {
  onFileUpload: (file: File) => void;
  label: string;
  filename?: string;
  error?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFileUpload,
  label,
  filename,
  error,
}) => {
  const toast = useToast();

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (acceptedFiles[0]) {
        onFileUpload(acceptedFiles[0]);
      }
      if (rejectedFiles.length > 0) {
        rejectedFiles.forEach((file) => {
          let errorMessage = "File upload failed.";
          if (file.errors[0]?.code === "file-too-large") {
            errorMessage = "File is too large. Maximum size is 5MB.";
          } else if (file.errors[0]?.code === "file-invalid-type") {
            errorMessage = "Invalid file type. Please upload a CSV file.";
          }
          toast({
            title: "Error",
            description: errorMessage,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
      }
    },
    [onFileUpload, toast]
  );

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    multiple: false,
    accept: { "text/csv": [".csv"] },
    maxFiles: 1,
    minSize: 0,
    maxSize: 5242880,
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneOptions);

  const rootProps: BoxProps = {
    ...getRootProps(),
    p: 4,
    border: "2px dashed",
    borderColor: isDragActive ? "gray.700" : error ? "red.500" : "#09389F",
    borderRadius: "md",
    textAlign: "center",
    cursor: "pointer",
    width: { base: "100%", md: "60%" },
    h: "100%",
    bg: "#0A389F1A",
  };

  const flexProps: FlexProps = {
    justifyContent: "center",
    alignItems: "center",
    h: "100%",
    flexDirection: "column",
  };

  return (
    <Flex flexDirection={{ base: "column", md: "row" }} flex={1} h="80%">
      <Box {...rootProps}>
        <input {...getInputProps()} />
        <Flex {...flexProps}>
          {filename ? (
            <>
              <Image
                src={uploadImage}
                h="30%"
                w="30%"
                objectFit="contain"
                alt="Upload"
              />
              <Text>File Uploaded : {filename}</Text>
            </>
          ) : (
            <>
              <Image
                src={uploadImage}
                h="30%"
                w="30%"
                objectFit="contain"
                alt="Upload"
              />
              {isDragActive ? (
                <Text>Drop the CSV file here</Text>
              ) : (
                <Text>{label}</Text>
              )}
            </>
          )}
        </Flex>
      </Box>
      <Box
        display="flex"
        width={{ base: "100%", md: "40%" }}
        h="100%"
        justifyContent="space-evenly"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
      >
        <Text>Supported Formats : CSV</Text>
        <Text>Maximum Size : 5 MB</Text>
      </Box>
    </Flex>
  );
};

interface FormErrors {
  configName?: string;
  file1?: string;
  file2?: string;
}

interface ApiResponse {
  success: boolean;
  message: string;
}

const CreateConfigurationForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [configName, setConfigName] = useState<string>("");
  const [file1, setFile1] = useState<File | null>(null);
  const [file2, setFile2] = useState<File | null>(null);
  const [errors, setErrors] = useState<FormErrors>({});

  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectStatus = (location.state as { redirect?: string })?.redirect;

  const handleFile1Upload = (file: File) => setFile1(file);
  const handleFile2Upload = (file: File) => setFile2(file);

  const resetForm = () => {
    setConfigName("");
    setFile1(null);
    setFile2(null);
    setErrors({});
  };

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    if (!configName.trim()) {
      newErrors.configName = "Configuration name is required";
    }
    if (!file1) {
      newErrors.file1 = "Price Elasticity file is required";
    }
    if (!file2) {
      newErrors.file2 = "Time To Patron file is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const mockApiCall = async (formData: FormData): Promise<ApiResponse> => {
    // Simulate API call delay
    await new Promise((resolve) => setTimeout(resolve, 1500));

    // Simulate successful response
    return { success: true, message: "Configuration added successfully" };
  };

  const handleSubmit = async (): Promise<void> => {
    if (validateForm()) {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("configName", configName);
        if (file1) formData.append("priceElasticityFile", file1);
        if (file2) formData.append("timeToPatronFile", file2);

        const response = await mockApiCall(formData);

        if (response.success) {
          toast({
            title: "Success",
            description: response.message,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          resetForm();
          if (redirectStatus) {
            navigate("/systemadmin/tenant/add", { replace: true });
          }
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to add configuration. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }else{
      toast({
        title: "Error",
        description: "Please fill in all required fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      id="addconfiguration-form"
      overflowX="auto"
      bg={"#FFFFFF10"}
      borderRadius="20px"
      display="flex"
      flexDirection="column"
      flex={1}
      fontFamily="Poppins"
      mx={{ base: "0vw", md: "15vw" }}
    >
      <Flex direction="column" flex={1}>
        <Flex
          bg="TableHeaderbg"
          justify="space-between"
          align="center"
          py="10px"
          px="20px"
          textColor="#0B389E"
          fontWeight={600}
          fontSize="16px"
        >
        Create Configuration
        </Flex>
        <Flex
          bg="Containerbg"
          flex={1}
          py="10px"
          px="20px"
          direction="column"
          justifyContent="space-between"
        >
          <VStack id="form-box" spacing={4} align="stretch" h="100%">
            <FormControl isRequired isInvalid={!!errors.configName}>
              <FormLabel fontWeight={500}>Configuration Name</FormLabel>
              <Input
                placeholder="Configuration name"
                bg="InputBoxbg"
                value={configName}
                _placeholder={{color:"PlaceHolderColor"}}
                onChange={(e) => setConfigName(e.target.value)}
                onFocus={() => {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    configName: "",
                  }));
                }}
              />
              <FormHelperText color={errors.configName ? "red.500" : "inherit"}>
                {errors.configName ||
                  "Enter the Configuration name you want to create"}
              </FormHelperText>
            </FormControl>
            <Flex flex={1}>
              <FormControl
                isRequired
                isInvalid={!!errors.file1}
                onFocus={() => {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    file1: "",
                  }));
                }}
              >
                <FormLabel fontWeight={500}>Price Elasticity</FormLabel>
                <FileUpload
                  onFileUpload={handleFile1Upload}
                  label="Drag and drop or click to upload"
                  filename={file1?.name}
                  error={!!errors.file1}
                />
                {errors.file1 && (
                  <FormHelperText color="red.500">
                    {errors.file1}
                  </FormHelperText>
                )}
              </FormControl>
            </Flex>
            <Flex flex={1}>
              <FormControl
                isRequired
                isInvalid={!!errors.file2}
                onFocus={() => {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    file2: "",
                  }));
                }}
              >
                <FormLabel fontWeight={500}>Time To Patron</FormLabel>
                <FileUpload
                  onFileUpload={handleFile2Upload}
                  label="Drag and drop or click to upload"
                  filename={file2?.name}
                  error={!!errors.file2}
                />
                {errors.file2 && (
                  <FormHelperText color="red.500">
                    {errors.file2}
                  </FormHelperText>
                )}
              </FormControl>
            </Flex>
          </VStack>
          <Flex
            id="button-box"
            justifyContent="flex-end"
            alignItems="flex-end"
            gap={3}
            mt={4}
          >
            <Button
              borderRadius="8px"
              bg="white"
              textColor="#0B389E"
              border="2px"
              borderColor="#5C85DC"
              onClick={() => resetForm()}
            >
              Cancel
            </Button>
            <Button
              borderRadius="8px"
              bgGradient="linear(to-b, #5C86DC,#2647B0)"
              textColor="white"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CreateConfigurationForm;
