import React, { useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
// import { Transition } from "@headlessui/react";
import instructorroutes from "instructor-routes";
import Header from "components/header";
import FooterCustom from "components/footer/FooterCustom";
import "assets/css/App.css";
import { useAuth } from "config/AuthDataProvider";
import { Box, ChakraProvider, Flex } from "@chakra-ui/react";
// import Chatbot from "views/chatbot/components";
// import {MdOutlineClose} from "react-icons/md";

/* eslint-disable @typescript-eslint/no-unused-vars */
/* @ts-ignore */

export default function Admin(props: { [x: string]: any }) {
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  const authData = useAuth();
  const navigate = useNavigate();
  React.useEffect(() => {
    getActiveRoute(instructorroutes);
  }, [location.pathname]);

  const getActiveRoute = (routes: RoutesType[]): string | boolean => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/instructor") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  document.documentElement.dir = "ltr";
  if (!authData || authData.isPlayer) {
    navigate(`/auth/login`);
  } else {
    return (
      <>
        {/* <ChakraProvider>
          <div className="h-full w-full flex-row">
            <Header setIsOpen={setIsOpen} />
            <div className="bg-color h-full w-full">
              <main className={`h-full flex-none transition-all`}>
                <div className="h-full">
                  <div className="pt-5s mx-auto mb-auto h-full min-h-[92vh] p-2 md:pr-2">
                    <Routes>
                      {getRoutes(instructorroutes)}

                      <Route
                        path="/"
                        element={<Navigate to="/auth/login" replace />}
                      />
                    </Routes>
                  </div>
                </div>
              </main>
              <div className="flex justify-center">
                <FooterCustom />
              </div>
            </div>
          </div>
        </ChakraProvider> */}
        <ChakraProvider>
        <Flex direction="column" minH="100vh">
          <Header setIsOpen={setIsOpen} />
          <Box flexGrow={1} bg="gray.100" bgGradient="linear(to-r, #F04E4A,#0537A0)" p={4} id="first">
              <Routes>
                {getRoutes(instructorroutes)}
                <Route path="/" element={<Navigate to="/auth/login" replace />} />
              </Routes>
          </Box>
          <FooterCustom />
        </Flex>
      </ChakraProvider>
      </>
    );
  }
}
