import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  useToast,
} from "@chakra-ui/react";
import Select, { components } from "react-select";
import { useNavigate } from "react-router-dom";
const options = [
  { label: "ROAI", value: "roai" },
  { label: "Blueocean", value: "blueocean" },
  { label: "TFS", value: "tfs" },
  { label: "AST", value: "ast" },
  { label: "GCP", value: "gcp" },
  { label: "EKS", value: "eks" },
];

const formFields = [
  {
    label: "Game 3 - Price Elasticity",
    id: "price-elasticity",
    helperText: "Select the game configuration for the client",
  },
  {
    label: "Game 3 - Time to Patron",
    id: "time-to-patron",
    helperText: "Select the game configuration for the client",
  },
];

interface FormErrors {
  clientName?: string;
  startDate?: string;
  endDate?: string;
  [key: string]: string | undefined;
}

const CreateClientForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [clientName, setClientName] = useState("");
  const [selectedGames, setSelectedGames] = useState<Record<string, any>>({});
  const [timeData, setTimeData] = useState<Record<string, any>>({
    startDate: "",
    endDate: "",
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const navigate = useNavigate();
  const toast = useToast();

  const CustomMenu = (props) => {
    return (
      <components.Menu {...props}>
        {props.children}
        <Button
          onClick={() =>
            navigate("/systemadmin/configuration/create", {
              state: { redirect: true },
            })
          }
          width={"100%"}
          bg={"transparent"}
          textColor={"#0B389E"}
          padding={2}
          cursor={"pointer"}
          borderRadius={0}
          borderTop={"1px"}
          borderColor={"gray.300"}
        >
          Create New Game Data
        </Button>
      </components.Menu>
    );
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF4D",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "30vh",
      overflowY: "auto",
    }),
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};
    if (!clientName.trim()) {
      newErrors.clientName = "Client name is required";
    }
    formFields.forEach((field) => {
      if (!selectedGames[field.id]) {
        newErrors[field.id] = "Game selection is required";
      }
    });
    Object.keys(timeData).forEach((key) => {
      if (!timeData[key]) {
        newErrors[key]="Date is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        toast({
          title: "Client created.",
          description: `${clientName} has been successfully created with the selected game configurations.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        resetForm();
      }, 1500);
    } else {
      toast({
        title: "Error",
        description: "Please fill in all required fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const resetForm = () => {
    setClientName("");
    setSelectedGames({});
    setTimeData({})
    setErrors({});
  };

  const handleGameChange = (selectedOption, fieldId) => {
    setSelectedGames((prev) => ({
      ...prev,
      [fieldId]: selectedOption,
    }));
  };

  return (
    <Box
      id="addclient-form"
      overflowX="auto"
      borderRadius={"20px"}
      display="flex"
      flexDirection="column"
      flex={1}
      fontFamily="Poppins"
      mx={{ base: "0vw", md: "15vw" }}
    >
      <Flex direction={"column"} flex={1}>
        <Flex
          bg={"TableHeaderbg"}
          justify="space-between"
          align={"center"}
          py={"10px"}
          px={"20px"}
          textColor="#0B389E"
          fontWeight={600}
          fontSize={"16px"}
        >
          Create Client
        </Flex>
        <Flex
          bg={"Containerbg"}
          flex={1}
          py={"10px"}
          px={"20px"}
          direction={"column"}
          justifyContent="space-between"
        >
          <Flex id="form-box" gap={4} direction={"column"}>
            <FormControl
              isRequired
              isInvalid={errors.clientName ? true : false}
              onFocus={() => {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  clientName: "",
                }));
              }}
            >
              <FormLabel>Client Name</FormLabel>
              <Input
                placeholder="Client name"
                bg={"InputBoxbg"}
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                _placeholder={{ color: "PlaceHolderColor" }}
              />
              <FormHelperText color={errors.clientName ? "red.500" : "inherit"}>
                {errors.clientName ||
                  "Enter the client name you want to create"}
              </FormHelperText>
            </FormControl>
            {formFields.map((field) => (
              <FormControl
                key={field.id}
                isRequired
                isInvalid={errors[field.id] ? true : false}
                onFocus={() => {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    [field.id]: "",
                  }));
                }}
              >
                <FormLabel>{field.label}</FormLabel>
                <Select
                  id={`select-${field.id}`}
                  classNamePrefix="select"
                  name={field.id}
                  isLoading={isLoading}
                  isClearable={true}
                  isSearchable={true}
                  options={options}
                  components={{ Menu: CustomMenu }}
                  styles={customStyles}
                  value={selectedGames[field.id] || null}
                  onChange={(selectedOption) =>
                    handleGameChange(selectedOption, field.id)
                  }
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                />
                <FormHelperText
                  color={errors[field.id] ? "red.500" : "inherit"}
                >
                  {errors[field.id] || field.helperText}
                </FormHelperText>
              </FormControl>
            ))}
            <Grid
              templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
              gap={2}
            >
              <FormControl
                isRequired
                isInvalid={errors.startDate ? true : false}
                onFocus={() => {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    startDate: "",
                  }));
                }}
              >
                <FormLabel>Start Date</FormLabel>
                <Input
                  placeholder="Select Start Date"
                  size="md"
                  type="date"
                  bg={"InputBoxbg"}
                  value={timeData?.startDate}
                  max={timeData?.endDate}
                  onChange={(e) =>
                    setTimeData((prev) => ({
                      ...prev,
                      startDate: e.target.value,
                    }))
                  }
                />
                <FormHelperText
                  color={errors.startDate ? "red.500" : "inherit"}
                >
                  {errors.startDate ||
                    "Enter the start date of client contract"}
                </FormHelperText>
              </FormControl>
              <FormControl
                isRequired
                isInvalid={errors.endDate ? true : false}
                onFocus={() => {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    endDate: "",
                  }));
                }}
              >
                <FormLabel>End Date</FormLabel>
                <Input
                  placeholder="Select End Date"
                  size="md"
                  type="date"
                  bg={"InputBoxbg"}
                  onChange={(e) =>
                    setTimeData((prev) => ({
                      ...prev,
                      endDate: e.target.value,
                    }))
                  }
                  value={timeData?.endDate}
                  min={timeData?.startDate}
                  isDisabled={timeData?.startDate===""}
                />
                <FormHelperText
                  color={errors.endDate ? "red.500" : "inherit"}
                >
                  {errors.endDate || "Enter the end date of client contract"}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Flex>
          <Flex
            id="button-box"
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            gap={3}
            mt={4}
          >
            <Button
              borderRadius={"8px"}
              bg={"white"}
              textColor={"#0B389E"}
              border={"2px"}
              borderColor={"#5C85DC"}
              py={2}
              px={4}
              onClick={resetForm}
            >
              Cancel
            </Button>
            <Button
              borderRadius={"8px"}
              bgGradient="linear(to-b, #5C86DC,#2647B0)"
              textColor={"white"}
              onClick={handleSubmit}
              isLoading={isLoading}
              py={2}
              px={4}
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CreateClientForm;
