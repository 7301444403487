import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import Select, { components } from "react-select";
import { useNavigate } from "react-router-dom";

const options = [
  { label: "ROAI", value: "roai" },
  { label: "Blueocean", value: "blueocean" },
  { label: "TFS", value: "tfs" },
  { label: "AST", value: "ast" },
  { label: "GCP", value: "gcp" },
  { label: "EKS", value: "eks" },
];

const roles = [
  { label: "User", value: "user" },
  { label: "Instructor", value: "instructor" },
  { label: "Spectator", value: "spectator" },
  { label: "SecondaryUser", value: "secondaryuser" },
  { label: "SecondaryInstructor", value: "secondaryinstructor" },
];

interface FormErrors {
  name?: string;
  email?: string;
  userRole?: string;
  tenantName?: string;
}

const CreateUserForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tenantName, setTenantName] = useState("");
  const [userRole, setUserRole] = useState(null);
  const [errors, setErrors] = useState<FormErrors>({});
  const navigate = useNavigate();
  const toast = useToast();

  const CustomMenu = (props) => {
    return (
      <components.Menu {...props}>
        {props.children}
        <Button
          onClick={() =>
            navigate("/systemadmin/user/role", {
              state: { redirect: true },
            })
          }
          width={"100%"}
          bg={"transparent"}
          textColor={"#0B389E"}
          padding={2}
          cursor={"pointer"}
          borderRadius={0}
          borderTop={"1px"}
          borderColor={"gray.300"}
        >
          Create User Role
        </Button>
      </components.Menu>
    );
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF4D",
    }),
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF4D",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '15vh',
      overflowY: 'auto',
    }),
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};
    if (!name.trim()) {
      newErrors.name = "User's name is required";
    }
    if (!email.trim()) {
      newErrors.email = "User's email is required";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
      if (!emailRegex.test(email)) {
        newErrors.email = "Please enter a valid email address";
      }
    }
    if (!tenantName) {
      newErrors.tenantName = "Tenant Name is required";
    }
    if (!userRole) {
      newErrors.userRole = "Game selection is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        toast({
          title: "Tenant created.",
          description: `${name} has been successfully created with ${userRole.label} game configuration.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        handleReset();
      }, 1500);
    } else {
      toast({
        title: "Error",
        description: "Please fill in all required fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleReset = () => {
    setName("");
    setEmail("");
    setTenantName(null);
    setUserRole(null);
    setErrors({});
  };

  return (
    <Box
      id="addtenant-form"
      overflowX="auto"
      // bg={"Containerbg"}
      borderRadius={"20px"}
      display="flex"
      flexDirection="column"
      flex={1}
      fontFamily="Poppins"
      mx={{ base: "0vw", md: "15vw" }}

    >
      <Flex direction={"column"} flex={1}>
        <Flex
          bg={"TableHeaderbg"}
          justify="space-between"
          align={"center"}
          py={"10px"}
          px={"20px"}
          textColor="#0B389E"
          fontWeight={600}
          fontSize={"16px"}
        >
          Create User
        </Flex>
        <Flex
          bg={"Containerbg"}
          flex={1}
          py={"10px"}
          px={"20px"}
          direction={"column"}
          justifyContent="space-between"
        >
          <Flex id="form-box" gap={4} direction={"column"}>
            <FormControl
              isRequired
              isInvalid={errors.name ? true : false}
              onFocus={() => {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  name: "",
                }));
              }}
            >
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="User Name"
                bg={"InputBoxbg"}
                value={name}
                _placeholder={{color:"PlaceHolderColor"}}
                onChange={(e) => setName(e.target.value)}
              />
              <FormHelperText color={errors.name ? "red.500" : "inherit"}>
                {errors.name ||
                  "Enter the name you want to create for the user."}
              </FormHelperText>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={errors.email ? true : false}
              onFocus={() => {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  email: "",
                }));
              }}
            >
              <FormLabel>Email</FormLabel>
              <Input
                placeholder="User Email"
                bg={"InputBoxbg"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                _placeholder={{color:"PlaceHolderColor"}}
                type='email'
              />
              <FormHelperText color={errors.email ? "red.500" : "inherit"}>
                {errors.email ||
                  "Enter the email address you want to create for the user."}
              </FormHelperText>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.tenantName}>
              <FormLabel>Select Tenant</FormLabel>
              <Select
                id="select-tenant-name"
                classNamePrefix="select"
                name="tenant"
                isLoading={isLoading}
                isClearable={true}
                isSearchable={true}
                options={options}
                autoFocus={true}
                required
                styles={customSelectStyles}
                value={tenantName}
                onChange={(value) => setTenantName(value)}
                onFocus={() => {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    tenantName: "",
                  }));
                }}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
              />
              <FormHelperText color={errors.tenantName ? "red.500" : "inherit"}>
                {errors.tenantName ||
                  "Select the game configuration for the tenant"}
              </FormHelperText>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={errors.userRole ? true : false}
              onFocus={() => {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  userRole: "",
                }));
              }}
            >
              <FormLabel>Select User Role</FormLabel>
              <Select
                id="select-game"
                classNamePrefix="select"
                name="color"
                isLoading={isLoading}
                isClearable={true}
                isSearchable={true}
                options={roles}
                autoFocus={true}
                required

                components={{ Menu: CustomMenu }}
                styles={customStyles}
                value={userRole}
                onChange={setUserRole}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
              />
              <FormHelperText
                color={errors.userRole ? "red.500" : "inherit"}
              >
                {errors.userRole ||
                  "Select the User Role for the user"}
              </FormHelperText>
            </FormControl>
          </Flex>
          <Flex
            id="button-box"
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            gap={3}
          >
            <Button
              borderRadius={"8px"}
              bg={"white"}
              textColor={"#0B389E"}
              border={"2px"}
              borderColor={"#5C85DC"}
              onClick={handleReset}
            >
              Cancel
            </Button>
            <Button
              borderRadius={"8px"}
              bgGradient="linear(to-b, #5C86DC,#2647B0)"
              textColor={"white"}
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CreateUserForm;