import React from "react";
import { Box, ChakraProvider, Container, Flex } from "@chakra-ui/react";
import SystemAdminHeader from "./Components/header";
import FooterCustom from "components/footer/FooterCustom";
import systemAdminRoutes from "systemadmin-routes";
import Links from "./Components/links";
import { Navigate, Route, Routes } from "react-router-dom";
import PageNotFound from "views/systemadmin/PageNotFound";

const getRoutes = (routes) => {
  return routes.map((prop, key) => {
    if (prop.layout === "/systemadmin") {
      if (prop.children) {
        return (
          <Route path={prop.path} key={key}>
            <Route
              index
              element={<Navigate to={prop.children[0].path} replace />}
            />
            {prop.children.map((child, childKey) => (
              <Route
                path={child.path}
                element={child.component}
                key={`${key}-${childKey}`}
              />
            ))}
          </Route>
        );
      } else {
        return <Route path={prop.path} element={prop.component} key={key} />;
      }
    } else {
      return null;
    }
  });
};

export default function SystemAdminLayout() {
  return (
      <Flex id="layout" flexDirection="column" minHeight="100vh">
        <Flex id="header" flexDirection="column">
          <Box as="header" bg="white">
            <SystemAdminHeader />
          </Box>
          <Box
            as="header"
            bg="white"
            textColor="black"
            className="shadow-[inset_0_8px_10px_-5px_#05369f4e]"
          >
            <Links routes={systemAdminRoutes} />
          </Box>
        </Flex>
        <Flex
          as="main"
          flex={1}
          overflow="auto"
          bgGradient="linear(to-r, #F04E4A,#0537A0)"
        >
          <Container
            id="main-container"
            maxW="3000px"
            py={8}
            display="flex"
            flexDirection="column"
            flex={1}
          >
            <Routes>
              {getRoutes(systemAdminRoutes)}
              <Route path="/" element={<Navigate to="/auth/login" replace />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Container>
        </Flex>
        <Box as="footer">
          <FooterCustom />
        </Box>
      </Flex>
  );
}
