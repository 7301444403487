import TenantListingTable from "views/systemadmin/client/Components/ClientListing";
import CreateTenantForm from "views/systemadmin/client/Components/CreateClient";
import TenantIndexPage from "views/systemadmin/client";
import ConfigurationIndexPage from "views/systemadmin/configuration/Components";
import CreateConfigurationForm from "views/systemadmin/configuration/Components/CreateConfiguration";
import WorkshopIndexPage from "views/systemadmin/workshop";
import CreateWorkShopForm from "views/systemadmin/workshop/Components/CreateWorkShop";
import UserIndexPage from "views/systemadmin/user";
import CreateUserForm from "views/systemadmin/user/Components/CreateUserForm";
import PermissionManagement from "views/systemadmin/user/Components/CreateUserRoles";

const data = [];
for (let i = 1; i <= 3; i++) {
  data.push({
    id: `id_${i.toString().padStart(4, "0")}`,
    name: `ROAI${i}`,
    created_date: `2023-08-${i.toString().padStart(2, "0")}`,
    price_elasticity: Math.random() * 10,
    time_to_patron: Math.floor(Math.random() * 10) + 1,
  });
}

const systemAdminRoutes = [
  {
    name: "Client",
    layout: "/systemadmin",
    path: "client",
    component: <TenantIndexPage />,
    children: [
      {
        name: "Listing",
        path: "listing",
        component: <TenantListingTable data={data} />,
      },
      {
        name: "Create Client",
        path: "create",
        component: <CreateTenantForm />,
      },
    ],
  },
  {
    name: "WorkShop",
    layout: "/systemadmin",
    path: "workshop",
    component: <WorkshopIndexPage />,
    children: [
      {
        name: "Create Workshop",
        path: "create",
        component: <CreateWorkShopForm />,
      },
    ],
  },
  {
    name: "Configuration",
    layout: "/systemadmin",
    path: "configuration",
    component: <ConfigurationIndexPage />,
    children: [
      {
        name: "Create Configuration",
        path: "create",
        component: <CreateConfigurationForm />,
      },
    ],
  },
  {
    name: "User",
    layout: "/systemadmin",
    path: "user",
    component: <UserIndexPage />,
    children: [
      {
        name: "Create User",
        path: "create",
        component: <CreateUserForm />,
      },
      {
        name: "Create User Roles",
        path: "roles",
        component: <PermissionManagement />,
      },
      
    ],
  },
];

export default systemAdminRoutes;
