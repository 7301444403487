import React from "react";
import { Box, Flex, HStack, Icon, Image } from "@chakra-ui/react";
import NavbarLogo from "../../../assets/game1/Logo.png";
import { GrNotification } from "react-icons/gr";
import { FaRegCircleUser } from "react-icons/fa6";
import FullScreenDropdown from "views/systemadmin/FullScreen";

export default function SystemAdminHeader() {
  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      boxShadow="0px 4px 10px 0px rgba(9, 56, 159, 0.25)"
      py={1}
      px={10}
    >
      <Box>
        <Image src={NavbarLogo} alt="Navbar Logo" h={10} w={16} />
      </Box>
      <HStack gap={4}>
        <FullScreenDropdown />
        <Icon as={GrNotification} h={7} w={7} cursor="pointer" />
        <Icon as={FaRegCircleUser} h={7} w={7} cursor="pointer" />
      </HStack>
    </Flex>
  );
}
