import { useState } from "react";
import { useGameStore } from "../../../store/store";
import "../game1A/animate.css";

export default function Game1AStep1Transition() {
  const patronList = useGameStore((state) => state.game1AStep1PatronList);
  const step1GroupIds = useGameStore((state) => state.step1GroupIds);
  const step2FirstGroupIds = useGameStore((state) => state.step2FirstGroupIds);
  const step2SecondGroupIds = useGameStore(
    (state) => state.step2SecondGroupIds
  );
  const incrementCurrentStep = useGameStore(
    (state) => state.incrementCurrentStep
  );

  let top1 = step2FirstGroupIds.length * 36 + 8 + 103;
  let top2 =
    (step2SecondGroupIds.length + step2FirstGroupIds.length) * 36 + 8 + 103;

  const [animate, setAnimate] = useState(false);
  const [Display, setDisplay] = useState(false);
  setTimeout(() => {
    setAnimate(true);
  }, 1000);
  setTimeout(() => {
    setDisplay(true);
  }, 2000);

  return (
    <div className="relative flex h-full w-2/3 flex-col items-center rounded-lg bg-white">
      <h1 className="mt-4 !text-[1.5vw] font-bold text-[#26337C]">
        You did it, here are the 3 patron segments you have created!
      </h1>
      <div className="animate-Table1 mt-2 flex w-[30%] !-translate-x-[110%] flex-col">
        <div className=" border-black flex animate-animateFadeIn flex-row rounded-lg border-2  bg-gradient-to-r from-[#C9D7FF] to-[#FFB8C7]">
          <h3 className="flex h-12 w-1/3 items-center rounded-l-lg pl-2 text-[1vw]  font-semibold">
            Patrons
          </h3>
          <h3 className="border-black flex h-12 w-1/3 items-center justify-center border-l-2 border-r-2 text-center text-[1vw]  text-sm font-semibold">
            Last Friday Sales
          </h3>
          <h3 className="flex h-12 w-1/3 items-center justify-center  rounded-r-lg text-center text-[1vw]  font-semibold">
            Total Last Year Sales
          </h3>
        </div>
        <ul className={`mt-1 flex w-full flex-col gap-1`}>
          {patronList
            .filter((patron) => step2FirstGroupIds.includes(patron.id))
            .map((patron) => (
              <div
                key={patron.id}
                className="flex flex-row rounded-lg border border-[#DDE8FF] text-[1vw]"
              >
                <div className="flex h-[23px] w-1/3 items-center rounded-l-lg bg-[#EAEEFF85]  pl-2 text-[1vw]">
                  {patron.name}
                </div>
                <div
                  className={`flex h-[23px] w-1/3 items-center justify-center  text-[1vw] ${
                    step1GroupIds.includes(patron.id)
                      ? "bg-color1"
                      : "bg-color2"
                  }`}
                >
                  {patron.actualDaily}
                </div>
                <div
                  className={`flex h-[23px] w-1/3 items-center justify-center  rounded-r-lg bg-color3  text-[1vw]`}
                >
                  {patron.lastYear}
                </div>
              </div>
            ))}
        </ul>
      </div>
      <ul
        className={`absolute flex w-[30%] flex-col gap-1  ${
          animate ? " animate-animateMoveUps" : ""
        }`}
        style={{ top: `${top1}px` }}
      >
        <div className=" border-black flex animate-animateFadeIn flex-row rounded-lg border-2  bg-gradient-to-r from-[#C9D7FF] to-[#FFB8C7]">
          <h3 className="flex h-12 w-1/3 items-center rounded-l-lg pl-2 text-[1vw]  font-semibold">
            Patrons
          </h3>
          <h3 className="border-black flex h-12 w-1/3 items-center justify-center  border-l-2 border-r-2 text-center text-[1vw] font-semibold">
            Last Friday Sales
          </h3>
          <h3 className="flex h-12 w-1/3  items-center justify-center  rounded-r-lg text-center text-[1vw] font-semibold">
            Total Last Year Sales
          </h3>
        </div>
        {patronList
          .filter((patron) => step2SecondGroupIds.includes(patron.id))
          .map((patron) => (
            <div
              key={patron.id}
              className="flex flex-row rounded-lg border border-[#DDE8FF] text-[1vw]"
            >
              <div className="flex h-[23px] w-1/3 items-center rounded-l-lg bg-[#EAEEFF85] pl-2 text-[1vw]">
                {patron.name}
              </div>
              <div
                className={`flex h-[23px] w-1/3 items-center justify-center text-[1vw] ${
                  step1GroupIds.includes(patron.id) ? "bg-color1" : "bg-color2"
                }`}
              >
                {patron.actualDaily}
              </div>
              <div
                className={`flex h-[23px] w-1/3 items-center justify-center rounded-r-lg bg-color4 pl-2 text-[1vw]`}
              >
                {patron.lastYear}
              </div>
            </div>
          ))}
      </ul>
      <ul
        className={`absolute flex w-[30%] flex-col gap-1  ${
          animate
            ? "translate-x-[110%] animate-animateMoveUps"
            : "animate-animateTable2"
        }`}
        style={{ top: `${top2}px` }}
      >
        <div className=" border-black flex animate-animateFadeIn flex-row rounded-lg border-2  bg-gradient-to-r from-[#C9D7FF] to-[#FFB8C7]">
          <h3 className="flex h-12 w-1/3 items-center rounded-l-lg pl-2 text-base font-semibold">
            Patrons
          </h3>
          <h3 className="border-black flex h-12 w-1/3 items-center justify-center border-l-2  border-r-2 text-center text-[1vw] font-semibold">
            Last Friday Sales
          </h3>
          <h3 className="flex h-12 w-1/3  items-center justify-center  rounded-r-lg text-center text-[1vw] font-semibold">
            Total Last Year Sales
          </h3>
        </div>
        {patronList
          .filter(
            (patron) =>
              !step2FirstGroupIds.includes(patron.id) &&
              !step2SecondGroupIds.includes(patron.id)
          )
          .map((patron) => (
            <div
              key={patron.id}
              className="flex flex-row rounded-lg border border-[#DDE8FF] text-[1vw]"
            >
              <div className="flex h-[23px] w-1/3 items-center rounded-l-lg bg-[#EAEEFF85] pl-2 text-[1vw]">
                {patron.name}
              </div>
              <div
                className={`flex h-[23px] w-1/3 items-center justify-center text-[1vw] ${
                  step1GroupIds.includes(patron.id) ? "bg-color1" : "bg-color2"
                }`}
              >
                {patron.actualDaily}
              </div>
              <div
                className={`flex h-[23px] w-1/3 items-center justify-center rounded-r-lg bg-color5 text-[1vw]`}
              >
                {patron.lastYear}
              </div>
            </div>
          ))}
      </ul>
      {Display ? (
        <button
          className="z-23 button-class absolute bottom-0 mb-2 flex rounded-md px-5 py-[3px] text-base text-white duration-300 ease-in-out hover:-translate-y-1 hover:bg-[#3072D6]"
          onClick={(e) => {
            e.preventDefault();
            incrementCurrentStep();
          }}
        >
          Continue
        </button>
      ) : null}
      {Display ? (
        <div className="absolute bottom-2 right-2 flex h-fit w-[23%] flex-col rounded-lg bg-[#0C399F]">
          <span className="border-slate-400 w-full rounded-t-lg border-x border-t bg-[#DDE8FF] px-2 py-1 font-semibold">
            Keys
          </span>
          <span className="w-full whitespace-nowrap border-l border-r border-color3 bg-color3 px-2 py-1 text-[0.8vw]">
            Segment 1: &nbsp; &lt;=
            {
              patronList.find(
                (patron) =>
                  patron.id ===
                  step2FirstGroupIds[step2FirstGroupIds.length - 1]
              )?.lastYear
            }
            &nbsp; Pints
          </span>
          <span className="w-full whitespace-nowrap border-l border-r border-color4 bg-color4 px-2 py-1 text-[0.8vw]">
            Segment 2: &nbsp; &gt;=
            {
              patronList[
                patronList.findIndex(
                  (patron) =>
                    patron.id ===
                    step2FirstGroupIds[step2FirstGroupIds.length - 1]
                ) + 1
              ].lastYear
            }
            &nbsp; Pints and &lt;= &nbsp;
            {
              patronList.find(
                (patron) =>
                  patron.id ===
                  step2SecondGroupIds[step2SecondGroupIds.length - 1]
              )?.lastYear
            }
          </span>
          <span className="w-full whitespace-nowrap rounded-b-lg border-b border-color5 bg-color5 px-2 py-1 text-[0.8vw]">
            Segment 3: &nbsp; &gt;=
            {
              patronList[
                patronList.findIndex(
                  (patron) =>
                    patron.id ===
                    step2SecondGroupIds[step2SecondGroupIds.length - 1]
                ) + 1
              ].lastYear
            }
            &nbsp; Pints
          </span>
        </div>
      ) : null}
    </div>
  );
}
