import React, { useState, useEffect } from "react";
import { Icon } from "@chakra-ui/react";
import { RxExitFullScreen, RxEnterFullScreen } from "react-icons/rx";

const FullScreenDropdown: React.FC = () => {
  const [isFullScreenMode, setIsFullScreenMode] = useState<boolean>(false);

  useEffect(() => {
    const exitHandler = (): void => {
      if (!document.fullscreenElement) {
        setIsFullScreenMode(false);
      }
    };

    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    document.addEventListener("MSFullscreenChange", exitHandler);

    return () => {
      document.removeEventListener("fullscreenchange", exitHandler);
      document.removeEventListener("webkitfullscreenchange", exitHandler);
      document.removeEventListener("mozfullscreenchange", exitHandler);
      document.removeEventListener("MSFullscreenChange", exitHandler);
    };
  }, []);

  const toggleFullscreen = (): void => {
    if (!document.fullscreenElement) {
      setIsFullScreenMode(true);
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if ((document.documentElement as any).mozRequestFullScreen) {
        (document.documentElement as any).mozRequestFullScreen();
      } else if ((document.documentElement as any).webkitRequestFullscreen) {
        (document.documentElement as any).webkitRequestFullscreen();
      } else if ((document.documentElement as any).msRequestFullscreen) {
        (document.documentElement as any).msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) {
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        (document as any).msExitFullscreen();
      }
    }
  };

  return (
    // <IconButton
    //     aria-label={isFullScreenMode ? "Exit fullscreen" : "Enter fullscreen"}
    //     icon={isFullScreenMode ? <RxExitFullScreen /> : <RxEnterFullScreen />}
    //     onClick={toggleFullscreen}
    //     variant="ghost"
    //     h={7}
    //     w={7}
    // />
    <Icon
      as={isFullScreenMode ? RxExitFullScreen : RxEnterFullScreen}
      h={7}
      w={7}
      cursor="pointer"
      onClick={toggleFullscreen}
    />
  );
};

export default FullScreenDropdown;
